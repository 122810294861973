import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialBillState: { data: any; isEOBEdited: boolean } = {
  data: null,
  isEOBEdited: false,
}

const editDOCSlice = createSlice({
  name: 'edit-doc',
  initialState: initialBillState,
  reducers: {
    setDataRedux(state, action: PayloadAction<any>) {
      state.data = action.payload
    },
    setisEOBEditedRedux(state, action: PayloadAction<any>) {
      state.isEOBEdited = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', (state) => {
      Object.assign(state, initialBillState)
    })
  },
})

export const { setDataRedux, setisEOBEditedRedux } = editDOCSlice.actions

export default editDOCSlice.reducer
