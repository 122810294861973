import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IPagination {
  id?: string
  page: number
  perPage: number
  order: "asc" | "desc"
  orderBy: string
  filter: any
  count:number
  search: string
  initialCall: boolean
  pathLocation:string
}

interface PaginationObj {
  data: IPagination
}

export const defaultPaginationData: IPagination = {
  filter: {},
  id: '',
  order: "desc"  ,
  orderBy: 'updatedAt',
  page: 0,
  count:0,
  perPage: 10,
  search: '',
  initialCall:true,
  pathLocation:""
}

const initialBillState: PaginationObj = {
  data: defaultPaginationData,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: initialBillState,
  reducers: {
    setDataRedux(state, action: PayloadAction<any>) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', (state) => {
      Object.assign(state, initialBillState)
    })
  },
})

export const { setDataRedux } = paginationSlice.actions

export default paginationSlice.reducer
