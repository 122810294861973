import { combineReducers } from '@reduxjs/toolkit'
import userSlice from './user.slice'
import customizationReducer from 'features/theme/customizationReducer'
import examineBillSlice from './examine-bill.slice'
import billSlice from './bill.slice'
import editDOCSlice from './edit-doc.slice'
import paginationSlice from './pagination.slice'
const rootReducer = combineReducers({
  user: userSlice,
  customization: customizationReducer,
  examineBill: examineBillSlice,
  bill: billSlice,
  editDOC: editDOCSlice,
  pagination : paginationSlice
})

export default rootReducer
