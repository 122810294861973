import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialBillState: { data: any; isGeneralInfoEdited: boolean } = {
  data: null,
  isGeneralInfoEdited: false,
}

const billSlice = createSlice({
  name: 'bill',
  initialState: initialBillState,
  reducers: {
    setDataRedux(state, action: PayloadAction<any>) {
      state.data = action.payload
    },
    setisGeneralInfoEditedRedux(state, action: PayloadAction<any>) {
      state.isGeneralInfoEdited = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', (state) => {
      Object.assign(state, initialBillState)
    })
  },
})

export const { setDataRedux, setisGeneralInfoEditedRedux } = billSlice.actions

export default billSlice.reducer
