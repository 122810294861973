import { lazy, Suspense } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  createBrowserRouter,
} from 'react-router-dom'
import { useAppSelector } from 'store/store'
import { Roles, IRoles } from 'utils/constant.utils'
import MainLayout from '../atic-common-helpers/layout/MainLayout'
import GuestRoute from './guestRoute.guard'
import ProtectedAdminRoute from './protectedAdminRoute.guard'
import ProtectedRoute from './protectedRoute.guard'
const Loader = lazy(() => import('component/ui-component/Loader'))
const PageNotFound = lazy(() => import('../pages/pagenotfound'))
const Login = lazy(() => import('../pages/authentication/Login'))

const Layout = lazy(() => import('../atic-common-helpers/layout/MainLayout'))
const BillList = lazy(() => import('../pages/medicalBills'))
const Admin = lazy(() => import('pages/admin'))
const AcceptedBillList = lazy(() => import('pages/acceptedBills'))
const DeniedBillList = lazy(() => import('pages/deniedBills'))
const CPTLookup = lazy(() => import('../pages/cptLookup'))
const EOB = lazy(() => import('../pages/generateEOB'))
const ExamineBill = lazy(() => import(`../pages/medicalBills/examineOrReview`))
const EditDOC = lazy(() => import('pages/editDOC'))
const { ADMIN, EXAMINER } = IRoles
import config from '../constant/config'

const appLoader = () => {
  return <Loader />
}

const RoutePath = createBrowserRouter(
  [
    {
      path: '/',
      element: <GuestRoute roles={[ADMIN, EXAMINER]} />,
      loader: appLoader,
      children: [
        {
          path: '',
          element: <Navigate to="login" replace />,
        },
        {
          path: '/login',
          element: <Login />,
        },
      ],
    },
    {
      path: '/',
      element: <ProtectedRoute roles={[ADMIN, EXAMINER]} />,
      children: [
        {
          path: '/medical-bills',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <BillList />,
            },
            {
              path: 'view-bill',
              element: <ExamineBill />,
            },
            {
              path: 'examine-bill',
              element: <ExamineBill />,
            },
          ],
        },
        {
          path: '/denied-bills',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <DeniedBillList />,
            },
            {
              path: 'edit-doc',
              element: <EditDOC />,
            },
            {
              path: 'view-bill',
              element: <ExamineBill />,
            },
          ],
        },
        {
          path: '/accepted-bills',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <AcceptedBillList />,
            },
            {
              path: 'view-bill',
              element: <ExamineBill />,
            },
          ],
        },
        {
          path: '/cpt-lookup',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <CPTLookup />,
            },
          ],
        },
      ],
    },

    {
      path: '/',
      element: <ProtectedAdminRoute roles={[ADMIN]} />,
      children: [
        {
          path: '/administration',
          element: <MainLayout />,
          children: [
            {
              path: 'user-management',
              element: <Admin />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
    {
      path: '404',
      element: <PageNotFound />,
    },
  ],
  {
    basename: config.basename,
  }
)

export default RoutePath
