import React from 'react'
import './loading.scss'

const Loading = () => {
  return (
    <div className="spinner-wrapper">
      <div className="spinner"></div>
    </div>
  )
}

export default Loading
