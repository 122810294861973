import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialUserState: {
  data: any
  State: any
  billLineItems: any
  activeTab: number
  focusedInput: string
  isRepriced: boolean
  isManualRepriced: boolean
  isDenied: boolean
  isBillEdited: boolean
  comments: string
  isCleared: boolean
  isSaved: boolean
} = {
  data: null,
  State: [],
  billLineItems: [],
  activeTab: 0,
  focusedInput: '',
  isRepriced: false,
  isManualRepriced: false,
  isDenied: false,
  isBillEdited: false,
  comments: '',
  isCleared: false,
  isSaved: false,
}

const examineBill = createSlice({
  name: 'examine-bill',
  initialState: initialUserState,
  reducers: {
    setDataRedux(state, action: PayloadAction<any>) {
      state.data = action.payload
    },
    setStateRedux(state, action: PayloadAction<any>) {
      state.State = action.payload
    },
    setBillLineItems(state, action: PayloadAction<any>) {
      state.billLineItems = action.payload
    },
    setActiveTab(state, action: PayloadAction<any>) {
      state.activeTab = action.payload
    },
    setFocusedInput(state, action: PayloadAction<string>) {
      state.focusedInput = action.payload
    },
    setBillRepriced(state, action: PayloadAction<boolean>) {
      state.isRepriced = action.payload
    },
    setBillManualRepriced(state, action: PayloadAction<boolean>) {
      state.isManualRepriced = action.payload
    },
    setBillDenied(state, action: PayloadAction<boolean>) {
      state.isDenied = action.payload
    },
    resetExamineDataRedux(state) {
      Object.assign(state, initialUserState)
    },
    setBillEdited(state, action: PayloadAction<boolean>) {
      state.isBillEdited = action.payload
    },
    setComments(state, action: PayloadAction<string>) {
      state.comments = action.payload
    },
    setBillCleared(state, action: PayloadAction<boolean>) {
      state.isCleared = action.payload
    },
    setBillSaved(state, action: PayloadAction<boolean>) {
      state.isSaved = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', (state) => {
      Object.assign(state, initialUserState)
    })
  },
})

export const {
  setDataRedux,
  setStateRedux,
  setBillLineItems,
  setActiveTab,
  setFocusedInput,
  setBillRepriced,
  setBillManualRepriced,
  setBillDenied,
  resetExamineDataRedux,
  setBillEdited,
  setBillSaved,
  setComments,
  setBillCleared,
} = examineBill.actions

export default examineBill.reducer
