import { Navigate, Outlet, useLocation } from 'react-router-dom'

const GuestRoute = (props: any) => {
  // const { auth } = useAuth()
  const auth = !!localStorage.getItem('at-mbr-user')
    ? JSON.parse(localStorage.getItem('at-mbr-user') || '')
    : null
  const token = !!localStorage.getItem('at-mbr-token')
    ? JSON.parse(localStorage.getItem('at-mbr-user') || '')
    : null
  let location = useLocation()
  return props?.roles?.includes(auth?.role) && !!token ? (
    <Navigate to={`/medical-bills`} state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}

export default GuestRoute
